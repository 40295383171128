import React from "react";
import { Theme } from "@mui/material";
import RHCheckmarkIcon from "icon-checkmark";

export type ContainerMaxWidth = "xs" | "sm" | "md" | "lg" | "xl" | false;

const propsFactory = (defaultTheme: Theme) => ({
  MuiContainer: {
    maxWidth: false as ContainerMaxWidth
  },
  MuiCheckbox: {
    disableRipple: true,
    checkedIcon: <RHCheckmarkIcon style={{ fontSize: 17 }} />,
    icon: <></>
  }
});

export default propsFactory;

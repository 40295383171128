import { adaptV4Theme, createTheme, Theme } from "@mui/material/styles";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  BREAKPOINT_SERVER_DESKTOP,
  BREAKPOINT_SERVER_MOBILE,
  FONT_BARON_SANS,
  FONT_BARON_SANS_LIGHT,
  FONT_BARON_SANS_THIN,
  FONT_BARON_SANS_ULTRA_THIN,
  FONT_CASLON,
  FONT_MEDIUM,
  FONT_REGULAR,
  FONT_THIN,
  FONT_LIGHT,
  SKELETON_BG_WHITE,
  SKELETON_BG_GREY,
  PAGE_BG_GREY,
  FONT_BARON_SANS_ROMAN
} from "utils/constants";
import brandThemeFactory from "./brandThemeFactory";
import propsFactory from "./props";
import mediaQuery from "css-mediaquery";
import "typeface-roboto";
import { processEnvServer } from "hooks/useSsrHooks";
import { useEnv } from "hooks/useEnv";
import { createStyles } from "@mui/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

export const getRhrMuiLink = (theme: Theme) => {
  return createStyles({
    root: {
      fontFamily: FONT_BARON_SANS,
      fontWeight: FONT_LIGHT,
      color: "#000000",
      letterSpacing: 0.5,
      textTransform: "initial",
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(13)
    },
    muiLink: {
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      textDecorationThickness: 0.5
    }
  });
};

declare module "@mui/material/styles" {
  interface Theme {
    brandColors: {
      priceRange: string;
      lightMemberPrice: string;
      headerIconLight: string;
      tempHeaderColor: string;
      tempBodyColor: string;
    };
    gutters?: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
    fontSizes?: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
      h1: number;
      h2: number;
      h3: number;
      h4: number;
      h5: number;
      h6: number;
      body1: number;
      body2: number;
      subtitle1: number;
      subtitle2: number;
      caption: number;
      button: number;
      overline: number;
    };
  }
  interface ThemeOptions {
    gutters?: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
    fontSizes?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
      xl?: number;
      h1?: number;
      h2?: number;
      h3?: number;
      h4?: number;
      h5?: number;
      h6?: number;
      body1?: number;
      body2?: number;
      subtitle1?: number;
      subtitle2?: number;
      caption?: number;
      button?: number;
      overline?: number;
    };
    brandColors?: {
      priceRange?: string;
      lightMemberPrice?: string;
      headerIconLight?: string;
      tempHeaderColor?: string;
      tempBodyColor?: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface DeprecatedThemeOptions extends ThemeOptions {}
}

export default props => {
  const {
    type = "light",
    brand = "RH",
    smDown = false,
    isRHR = true,
    reqContext = {}
  } = props;
  const env = useEnv();
  const ssrToggle = env?.FEATURE_SSR;
  const defaultTheme = createTheme(
    adaptV4Theme({
      typography: {
        htmlFontSize: 12
      },
      palette: {
        mode: type,
        background: {
          default: `${PAGE_BG_GREY}`
        }
      },
      spacing: 8,
      breakpoints: {
        values: {
          xs: BREAKPOINT_XS,
          sm: BREAKPOINT_SM,
          md: BREAKPOINT_MD,
          lg: BREAKPOINT_LG,
          xl: BREAKPOINT_XL
        }
      },
      gutters: { xs: 16, sm: 32, md: 40, lg: 40, xl: 80 },
      fontSizes: {
        xs: 11,
        sm: 12,
        md: 13,
        lg: 14,
        xl: 16,
        h1: 26,
        h2: 22,
        h3: 18,
        h4: 14,
        h5: 12,
        h6: 14,
        body1: 13,
        body2: 14,
        subtitle1: 14,
        subtitle2: 18,
        caption: 12,
        button: 12,
        overline: 11
      }
    })
  );

  const baseTheme = adaptV4Theme({
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: "none"
        },
        colorDefault: {
          backgroundColor: defaultTheme.palette.background.paper
        }
      },
      MuiContainer: {
        root: {
          paddingLeft: defaultTheme.gutters.xs,
          paddingRight: defaultTheme.gutters.xs,
          [defaultTheme.breakpoints.up("sm")]: {
            paddingLeft: defaultTheme.gutters.sm,
            paddingRight: defaultTheme.gutters.sm
          },
          [defaultTheme.breakpoints.up("md")]: {
            paddingLeft: defaultTheme.gutters.md,
            paddingRight: defaultTheme.gutters.md
          },
          [defaultTheme.breakpoints.up("lg")]: {
            paddingLeft: defaultTheme.gutters.lg,
            paddingRight: defaultTheme.gutters.lg
          },
          [defaultTheme.breakpoints.up("xl")]: {
            paddingLeft: defaultTheme.gutters.xl,
            paddingRight: defaultTheme.gutters.xl
          },
          backgroundColor: defaultTheme.palette.background.default
        }
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: 12,
          marginBottom: 8
        }
      },
      MuiFormLabel: {
        root: {
          "&.Mui-error": {
            transform: "translate(20px, -130%)"
          },
          cursor: "pointer",
          fontSize: "14px"
        }
      },
      MuiFormHelperText: {
        root: {
          "&.Mui-error": {
            color: "#CA6667"
          }
        }
      },
      MuiInput: {
        underline: {
          paddingBottom: 10,
          "&:before": {
            transition: "none",
            borderBottom: "1px solid #d8d8d8"
          },
          "&:after": {
            transition: "none",
            borderBottom: "1px solid #d8d8d8"
          }
        }
      },
      MuiInputBase: {
        root: {
          color: "#000000",
          backgroundColor: "#ffffff",
          "&.Mui-disabled": {
            color: "#c9c9c9",
            backgroundColor: "#fafafa",
            "& > .MuiSelect-icon": {
              color: "#aeaeae"
            }
          }
        },
        input: {
          color: "inherit",
          fontSize: defaultTheme.typography.pxToRem(14),
          padding: "14px !important"
        }
      },
      MuiInputLabel: {
        root: {
          color: "#999999 !important",
          "&.Mui-disabled": {
            color: "#c9c9c9",
            opacity: 0.5
          },
          "&.Mui-focused": {
            color: "#999999 !important"
          },
          "&.MuiInputLabel-outlined": {
            fontSize: "1rem" // Replace with defaultTheme.typography.body1.fontSize if needed
          },
          "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.875)"
          }
        },
        outlined: {
          top: "50%",
          transform: "translate(15px, -50%)",
          "&.MuiInputLabel-shrink": {
            top: 0
          }
        }
      },
      MuiOutlinedInput: {
        input: {
          padding: "14px"
        },
        notchedOutline: {
          borderWidth: 1,
          borderColor: "#E9E9E9 !important",
          borderRadius: 0,
          "& legend": {
            "& span": {
              letterSpacing: "0.34pt"
            }
          },
          fontSize: 14
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1
          }
        }
      },
      MuiInputAdornment: {
        root: {
          fontSize: defaultTheme.typography.pxToRem(11),
          color: "#666666"
        }
      },
      MuiSelect: {
        root: {
          whiteSpace: "normal",
          height: "auto",
          lineHeight: "17px",
          paddingRight: 40,
          backgroundColor: "rgb(249, 247, 244)"
        },
        select: {
          "&:focus": {
            backgroundColor: "rgb(249, 247, 244)"
          },
          "& option": {
            cursor: "pointer",
            fontSize: "13pt"
          }
        },
        icon: {
          color: "inherit",
          top: "initial",
          right: 14
        },
        iconOutlined: {
          right: 14
        }
      },
      MuiCheckbox: {
        root: {
          width: 30,
          height: 30,
          padding: `0px ${ssrToggle ? "!important" : ""}`,
          margin: `5px 10px 5px ${ssrToggle ? "!important" : ""}`,
          border: `1px solid #E9E9E9 ${ssrToggle ? "!important" : ""}`,
          borderRadius: `initial ${ssrToggle ? "!important" : ""}`,
          backgroundColor: `#ffffff ${ssrToggle ? "!important" : ""}`,
          "&:hover": {
            backgroundColor: `#ffffff ${ssrToggle ? "!important" : ""}`,
            borderColor: `#CCC ${ssrToggle ? "!important" : ""}`
          }
        },
        colorSecondary: {
          "&.Mui-checked": {
            borderColor: `#CCCCCC ${ssrToggle ? "!important" : ""}`,
            color: `#000000 ${ssrToggle ? "!important" : ""}`,
            "&&:hover": {
              backgroundColor: `#ffffff ${ssrToggle ? "!important" : ""}`
            }
          }
        }
      },
      MuiRadio: {
        root: {
          color: "#E9E9E9"
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: "#CCCCCC",
            "&&:hover": {
              backgroundColor: "unset"
            }
          }
        }
      },
      MuiButton: {
        root: {
          fontFamily: FONT_BARON_SANS,
          fontSize: defaultTheme.typography.pxToRem(12),
          fontWeight: FONT_REGULAR,
          color: "#999999",
          lineHeight: "normal",
          letterSpacing: 1,
          padding: `15px ${ssrToggle ? "!important" : ""}`,
          borderRadius: 0,
          boxShadow: "none !important"
        },
        text: {
          padding: undefined
        },
        contained: {
          "&.Mui-disabled": {
            color: "#FFFFFF",
            backgroundColor: "#DDDDDD"
          }
        },
        containedPrimary: {
          color: "#FFFFFF",
          backgroundColor: "#000000",
          "&:hover": {
            backgroundColor: "#404040",
            borderColor: "#404040"
          }
        },
        containedSecondary: {
          color: "#000000",
          backgroundColor: "#FFFFFF",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#cccccc",
          "&:hover": {
            backgroundColor: "#fafafa",
            borderColor: "#cccccc"
          }
        }
      },
      MuiMobileStepper: {
        root: {
          padding: 10
        },
        dot: {
          width: 6,
          height: 6,
          margin: "0 3px",
          backgroundColor: "#ffffff80"
        },
        dotActive: {
          backgroundColor: "#ffffff"
        }
      },
      MuiLink: {
        root: {
          fontFamily: FONT_BARON_SANS,
          fontWeight: FONT_REGULAR,
          fontSize: defaultTheme.typography.pxToRem(13),
          lineHeight: defaultTheme.typography.pxToRem(20),
          color: "#666666",
          letterSpacing: 0.2,
          textTransform: "initial",
          padding: 0
        },
        underlineHover: {
          "&:hover": {
            textDecoration: "none",
            color: "#333333"
          }
        }
      },
      MuiCard: {
        root: {
          width: "100%"
        }
      },
      MuiListItem: {
        root: {
          display: "flex !important",
          justifyContent: "start !important",
          paddingTop: "5px !important",
          paddingBottom: "5px !important",
          "&:empty": {
            padding: 0
          }
        },
        gutters: {
          paddingLeft: "16px !important",
          paddingRight: "16px !important"
        }
      },
      MuiBackdrop: {
        root: {
          top: 0,
          bottom: 0,
          position: "absolute",
          opacity: 0.6,
          backgroundColor: "transparent"
        }
      },
      MuiDrawer: {
        paper: {
          boxShadow: "0 0 2px 2px #0000001a",
          overflowY: "unset"
        }
      },
      MuiDialog: {
        paper: {
          boxShadow: "0 1px 2px 1px #0000001a"
        }
      },
      MuiDialogTitle: {
        root: {
          backgroundColor: PAGE_BG_GREY
        }
      },
      MuiDialogContent: {
        root: {
          margin: 0,
          [defaultTheme.breakpoints.up("xs")]: {
            overflow: "hidden",
            paper: {
              padding: 0
            },
            padding: "40px 30px",
            overflowY: "auto"
          },
          [defaultTheme.breakpoints.up("sm")]: {
            overflow: "hidden",
            paper: {
              padding: 0
            },
            padding: "60px 30px"
          },
          [defaultTheme.breakpoints.up("md")]: {
            padding: "60px"
          },
          [defaultTheme.breakpoints.up("lg")]: {
            padding: "60px"
          },
          [defaultTheme.breakpoints.up("xl")]: {
            padding: "80px"
          },
          backgroundColor: defaultTheme.palette.background.default
        }
      },
      MuiIconButton: {
        root: {
          padding: 10,
          fontSize: defaultTheme.typography.pxToRem(25),
          "&:hover": {
            backgroundColor: "unset"
          }
        },
        colorPrimary: {
          "&:hover": {
            backgroundColor: "unset"
          }
        }
      },
      MuiTouchRipple: {
        rippleVisible: {
          opacity: 0,
          animation: "unset"
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "#f5f5f5",
          padding: "40px 30px"
        },
        tooltipPlacementTop: {
          margin: 0
        },
        popper: {
          pointerEvents: "auto"
        },
        arrow: {
          fontSize: `12 ${ssrToggle ? "!important" : ""}`,
          color: "#f5f5f5"
        }
      },
      MuiLinearProgress: {
        root: {
          height: 2
        }
      },
      MuiTypography: {
        root: {
          textTransform: "uppercase" as const,
          fontSize: defaultTheme.typography.pxToRem(12),
          display: "block",
          "word-break": "break-word"
        },

        h5: {
          fontFamily: FONT_BARON_SANS,
          fontWeight: FONT_MEDIUM,
          fontSize: defaultTheme.typography.pxToRem(12),
          lineHeight: defaultTheme.typography.pxToRem(18),
          color: "#000000",
          letterSpacing: 1
        },
        h6: {
          fontFamily: FONT_CASLON,
          fontWeight: FONT_THIN,
          fontSize: defaultTheme.typography.pxToRem(14),
          lineHeight: defaultTheme.typography.pxToRem(15),
          color: "#000000",
          letterSpacing: 0,
          [defaultTheme.breakpoints.up("lg")]: {
            fontSize: defaultTheme.typography.pxToRem(15),
            lineHeight: defaultTheme.typography.pxToRem(15)
          },
          [defaultTheme.breakpoints.up("lg")]: {
            fontSize: defaultTheme.typography.pxToRem(15),
            lineHeight: defaultTheme.typography.pxToRem(15)
          },
          [defaultTheme.breakpoints.up("xl")]: {
            fontSize: defaultTheme.typography.pxToRem(16),
            lineHeight: defaultTheme.typography.pxToRem(16)
          }
        },
        subtitle1: {
          fontFamily: FONT_BARON_SANS,
          fontSize: defaultTheme.typography.pxToRem(14),
          lineHeight: defaultTheme.typography.pxToRem(20),
          color: "#000000",
          letterSpacing: 0.5
        },
        subtitle2: {
          fontFamily: FONT_BARON_SANS,
          fontWeight: FONT_THIN,
          fontSize: defaultTheme.typography.pxToRem(18),
          lineHeight: defaultTheme.typography.pxToRem(22),
          color: "#000000",
          letterSpacing: 0.2
        },
        button: {
          fontFamily: FONT_BARON_SANS,
          fontWeight: FONT_MEDIUM,
          fontSize: defaultTheme.typography.pxToRem(12),
          lineHeight: "normal"
        },
        h1: {
          fontFamily: FONT_BARON_SANS_ULTRA_THIN,
          fontSize: defaultTheme.typography.pxToRem(32),
          lineHeight: defaultTheme.typography.pxToRem(32),
          color: "#000000",
          letterSpacing: "-0.02em"
        },
        h2: {
          fontFamily: FONT_BARON_SANS_ULTRA_THIN,
          fontSize: defaultTheme.typography.pxToRem(27),
          lineHeight: defaultTheme.typography.pxToRem(31),
          color: "#000000",
          letterSpacing: "-0.02em"
        },
        h3: {
          fontFamily: FONT_BARON_SANS_ULTRA_THIN,
          fontSize: defaultTheme.typography.pxToRem(20),
          lineHeight: defaultTheme.typography.pxToRem(23),
          color: "#000000",
          letterSpacing: "-0.02em"
        },
        h4: {
          fontFamily: FONT_BARON_SANS,
          fontWeight: FONT_LIGHT,
          fontSize: defaultTheme.typography.pxToRem(15),
          lineHeight: defaultTheme.typography.pxToRem(17),
          color: "#000000",
          letterSpacing: 0
        },
        body1: {
          fontFamily: FONT_BARON_SANS_THIN,
          fontWeight: FONT_LIGHT,
          fontSize: defaultTheme.typography.pxToRem(13),
          lineHeight: defaultTheme.typography.pxToRem(20),
          color: defaultTheme.palette.common.black,
          letterSpacing: "0.015em",
          textTransform: "initial" as const
        },
        body2: {
          fontFamily: FONT_BARON_SANS_ROMAN,
          fontSize: defaultTheme.typography.pxToRem(13),
          lineHeight: defaultTheme.typography.pxToRem(20),
          color: defaultTheme.palette.common.black,
          letterSpacing: "0.015em",
          textTransform: "initial" as const
        },
        caption: {
          fontFamily: FONT_BARON_SANS_LIGHT,
          fontSize: defaultTheme.typography.pxToRem(11),
          lineHeight: defaultTheme.typography.pxToRem(13),
          color: "#000000",
          letterSpacing: "0.04em",
          textTransform: "initial" as const
        }
      },
      // MuiPickersCalendarHeader: {
      //   transitionContainer: {
      //     "& p": {
      //       fontFamily: FONT_BARON_SANS,
      //       fontWeight: FONT_MEDIUM,
      //       fontSize: defaultTheme.typography.pxToRem(14),
      //       lineHeight: defaultTheme.typography.pxToRem(24),
      //       color: "#000000",
      //       letterSpacing: 1,
      //       textTransform: "uppercase"
      //     }
      //   },
      //   dayLabel: {
      //     fontFamily: FONT_BARON_SANS,
      //     fontWeight: FONT_MEDIUM,
      //     fontSize: defaultTheme.typography.pxToRem(14),
      //     lineHeight: defaultTheme.typography.pxToRem(24),
      //     color: "#000000",
      //     letterSpacing: 1,
      //     textTransform: "uppercase"
      //   }
      // },
      // MuiPickersDay: {
      //   day: {
      //     width: 35,
      //     height: 35,
      //     borderRadius: 0,
      //     fontFamily: FONT_BARON_SANS,
      // fontSize: defaultTheme.typography.pxToRem(13),
      // lineHeight: defaultTheme.typography.pxToRem(20),
      //     textTransform: "initial" as const,
      //     color: "#666666",
      //     letterSpacing: 0.2,

      //     "&:hover": {
      //       backgroundColor: "#f5f5f5",
      //       color: "#000000"
      //     }
      //   },
      //   daySelected: {
      //     width: 35,
      //     height: 35,
      //     backgroundColor: "black",
      //     borderRadius: 0,
      //     color: "#ffffff",

      //     "&:hover": {
      //       color: "#ffffff"
      //     }
      //   }
      // },

      MuiPickersDay: {
        root: {
          width: 35,
          height: 35,
          borderRadius: 0,
          fontFamily: FONT_BARON_SANS,
          fontSize: defaultTheme.typography.pxToRem(13),
          lineHeight: defaultTheme.typography.pxToRem(20),
          textTransform: "initial",
          color: "#666666",
          letterSpacing: "0.2px",
          "&:hover": {
            backgroundColor: "#f5f5f5",
            color: "#000000"
          },
          "&.Mui-selected": {
            backgroundColor: "black",
            color: "#ffffff",
            "&:hover": {
              color: "#ffffff"
            }
          }
        }
      },
      MuiSvgIcon: {
        root: {
          "-webkit-filter": "blur(0px)",
          "-moz-filter": "blur(0px)",
          "-ms-filter": "blur(0px)",
          filter: `progid:DXImageTransform.Microsoft.Blur(PixelRadius='0')`
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: defaultTheme.palette.background.default || "#ffffff"
        }
      },
      MuiCssBaseline: {
        "@global": {
          ".MuiSkeleton-root": {
            backgroundColor:
              defaultTheme.palette.background.default === `${PAGE_BG_GREY}`
                ? `${SKELETON_BG_GREY} !important`
                : `${SKELETON_BG_WHITE} !important`
          }
        }
      }
    },
    props: propsFactory(defaultTheme),
    breakpoints: defaultTheme.breakpoints,
    gutters: defaultTheme.gutters,
    fontSizes: defaultTheme.fontSizes,
    mixins: {
      toolbar: {
        minHeight: 50,
        paddingLeft: 16,
        paddingRight: 5
      }
    },
    typography: {
      fontFamily: FONT_BARON_SANS,
      htmlFontSize: 12
    },
    spacing: factor => {
      return (smDown || isRHR ? 8 : 10) * factor;
    },
    palette: {
      primary:
        type === "light"
          ? {
              light: "#484848",
              main: "#212121",
              dark: "#000000",
              contrastText: "#ffffff"
            }
          : {
              light: "#ffffff",
              main: "#fafafa",
              dark: "#c7c7c7",
              contrastText: "#000000"
            },
      secondary: {
        light: "#e8e8e8",
        main: "#bdbdbd",
        dark: "#8d8d8d",
        contrastText: "#fafafa"
      },
      background: {
        default: defaultTheme.palette.background.default
      },
      mode: type
    },
    brandColors: {
      priceRange: "#000000",
      lightMemberPrice: "#FFFFFF",
      headerIconLight: "#FFFFFF",
      tempHeaderColor: "#000000", // TODO: remove once RH Modern has own styles
      tempBodyColor: "#666666" // TODO: remove once RH Modern has own styles
    }
  });

  const customComponents = {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true
      }
    }
  };

  const theme = createTheme({
    ...baseTheme,
    components: {
      ...(baseTheme?.components || {}),
      ...customComponents
    }
  });

  const req: any = processEnvServer ? reqContext : {};

  let providerTheme;
  let mediaQueryProps: any = {};
  if (processEnvServer) {
    const deviceType = req?.cookies?.PF_EXP?.toLowerCase() || "desktop";
    const ssrMatchMedia = query => ({
      matches: mediaQuery.match(query, {
        width:
          deviceType === "mobile"
            ? `${BREAKPOINT_SERVER_MOBILE}px`
            : `${BREAKPOINT_SERVER_DESKTOP}px`
      })
    });
    mediaQueryProps = {
      ssrMatchMedia
    };
  }
  providerTheme = {
    ...theme,
    props: {
      // @ts-ignore
      ...theme?.props,
      MuiUseMediaQuery: mediaQueryProps
    }
  };

  return brandThemeFactory(providerTheme, brand);
};
